import React, { useEffect, useState } from "react"

import { FormGroup, Input, Label } from "reactstrap"
import Image from "../../components/Image"
import { useLocation } from "@reach/router"
import qs from "qs"
import axios from "axios"
import { GTMOnRouteUpdate, sendConversion } from "../GTM"
import PlacesAutocomplete from "react-places-autocomplete"
import { useCookies } from "react-cookie"
import Loadable from "@loadable/component"
import Accordion from "react-bootstrap/Accordion"
import AccordionHeaderCustomScroll from "../AccordionHeaderCustomScroll"
import RangeSlider from "react-bootstrap-range-slider"
const RealtorSlider = Loadable(() => import("../RealtorSlider"))

const SearchAgent = ({ customTitle = null, isIframe = false }) => {
  const location = useLocation()
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [accordion, setAccordion] = useState("art")
  const [searchAgent, setSearchAgent] = useState("Kauf")
  const [realEstate, setRealEstate] = useState("Auswahl")

  const [city, setCity] = useState(urlParams.city || "")
  const [radius, setRadius] = useState(50)
  const [area, setArea] = useState(100)
  const [rooms, setRooms] = useState("")
  const [price, setPrice] = useState("")

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const [mapsLoaded, setMapsLoaded] = useState(false)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [cookies] = useCookies()
  const mzCookies = cookies.maklerzentrale || {}

  function getLabel(val, min, max) {
    if (val <= min) {
      return `< ${min} m²`
    } else if (val >= max) {
      return `> ${max} m²`
    } else {
      return `${val} m²`
    }
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script")
    script.type = "text/javascript"
    script.id = "mapsApi"

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
  }

  const handleSubmit = event => {
    event.preventDefault()

    setIsLoading(true)
    // sendConversion("search_agent_submit")
    axios
      .post(
        process.env.NODE_ENV === `production`
          ? `https://partner.maklerzentrale.com/api/searchAgent`
          : `http://partner-maklerzentrale.test/api/searchAgent`,
        {
          searchAgent,
          realEstate,
          city,
          radius,
          area,
          rooms,
          price,
          fullName,
          email,
          phone,
          ref: location.href,
        }
      )
      .then(response => {
        console.log(response)
        setShowSuccessMessage(true)
      })
      .catch(error => {
        console.log(error)
        alert(
          "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder schreiben Sie uns eine E-Mail."
        )
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    // todo: only when cookie banner accepted!
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_MAPS_API}&libraries=places`,
      () => setMapsLoaded(true)
    )
    return function unmount() {
      //unload script
      document.getElementById("mapsApi").remove()
    }
  }, [])

  return (
    <form className="p-6" onSubmit={handleSubmit}>
      <div className="scroll-beacon" id="besser-mit-makler" />

      {showSuccessMessage ? (
        <div className="text-center mt-5">
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Vielen Dank.
            <br />
            Wir werden uns bei einem Treffer Ihres Suchauftrags melden.
          </p>
          <hr
            className="my-2 my-sm-5"
            style={{ border: ".13rem solid #d3cbb5", borderRadius: "2px" }}
          />
          <h3 style={{ fontSize: "1.5rem" }}>
            Immobilienkompetenz mit regionaler Marktexpertise
          </h3>

          {/*<div*/}
          {/*  style={{*/}
          {/*    position: "absolute",*/}
          {/*    left: "50%",*/}
          {/*    transform: "translateX(-50%)",*/}
          {/*    opacity: 0.95,*/}
          {/*    width: "100%",*/}
          {/*    zIndex: 1,*/}
          {/*    background:*/}
          {/*      "linear-gradient(90deg, rgba(255,255,255,0) 20%, rgba(255,255,255,.8) 45%, rgba(255,255,255,.8) 55%, rgba(255,255,255,0) 80%)",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Image*/}
          {/*    src={"/custom/top100makler.png"}*/}
          {/*    className={`mx-auto`}*/}
          {/*    style={{*/}
          {/*      // position: "absolute",*/}
          {/*      // left: "50%",*/}
          {/*      // transform: "translateX(-50%)",*/}
          {/*      // opacity: 0.8,*/}
          {/*      width: "215px",*/}
          {/*      // backdropFilter: "blur(10px)",*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="bg-white" style={{ position: "relative" }}>
            <RealtorSlider />
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                left: 0,
                top: 0,
                // opacity: 0.95,
                zIndex: 1,
                background:
                  "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)",
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <Image
            src="/assets/logo/Maklerzentrale-Schrift_fw.png"
            loadFrom="logo"
            style={{ height: "auto", width: "180px" }}
            className="footer-brand img-fluid"
            alt="Maklerzentrale Logo"
          />
          <h1
            className="text-start my-3 fw-bold"
            style={{ fontSize: "1.5rem" }}
          >
            {customTitle || "Sie suchen eine Immobilie?"}
          </h1>
          <p>
            Wenn Sie einen Suchwunsch bei der Maklerzentrale hinterlegen, werden
            Sie umgehend per E-Mail informiert, sobald eine passende Immobilie
            verfügbar ist.
          </p>

          {/*      <div className="col-12 col-md-5 d-none d-md-block">*/}
          {/*        <Image*/}
          {/*          src="top100makler.png"*/}
          {/*          loading="eager"*/}
          {/*          imgStyle={{*/}
          {/*            objectFit: "contain",*/}
          {/*          }}*/}
          {/*          className="rounded mx-auto"*/}
          {/*        />*/}
          {/*      </div>*/}

          <Accordion
            defaultActiveKey="art"
            activeKey={accordion}
            className="card shadow-light-lg w-auto mx-n6 m-sm-0 mb-5 mb-md-6"
          >
            <Accordion.Item eventKey="art">
              <AccordionHeaderCustomScroll
                anchor="art"
                onClick={e => {
                  setAccordion("art")
                }}
              >
                <span
                  className={`badge badge-lg badge-rounded-circle me-2 ${
                    accordion === "art" ? "bg-primary" : "bg-light"
                  }`}
                >
                  <span>1</span>
                </span>
                Suchwunsch hinterlegen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Hier können Sie Angaben zu Ihrer gewünschten Immobilie machen.
                  Sie erhalten kostenlos Exposés von Immobilien, die Ihren
                  Suchkriterien entsprechen, noch bevor sie öffentlich inseriert
                  werden.
                </p>

                <div className="btn-group btn-group-sm w-100 mb-2" role="group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="searchAgent"
                    id="searchAgentKauf"
                    value="Kauf"
                    onChange={e => setSearchAgent(e.target.value)}
                  />
                  <label
                    className={`btn ${
                      searchAgent === "Kauf"
                        ? "btn-primary active"
                        : "btn-white"
                    }`}
                    for="searchAgentKauf"
                  >
                    Kauf
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="searchAgent"
                    id="searchAgentMiete"
                    value="Miete"
                    onChange={e => setSearchAgent(e.target.value)}
                  />
                  <label
                    className={`btn ${
                      searchAgent === "Miete"
                        ? "btn-primary active"
                        : "btn-white"
                    }`}
                    for="searchAgentMiete"
                  >
                    Miete
                  </label>
                </div>

                <select
                  name="realEstate"
                  className="form-select form-select-sm my-2"
                  value={realEstate}
                  onChange={e => setRealEstate(e.target.value)}
                >
                  <option value="Auswahl">Welche Immobilie suchen Sie?</option>
                  <option value="Wohnung">Wohnung</option>
                  <option value="Einfamilienhaus">Einfamilienhaus</option>
                  <option value="Mehrfamilienhaus">Mehrfamilienhaus</option>
                  <option value="Grundstück">Grundstück</option>
                  <option value="Gewerbe">Gewerbeimmobilie</option>
                </select>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="immobilie">
              <AccordionHeaderCustomScroll
                anchor="immobilie"
                onClick={e => {
                  setAccordion("immobilie")
                }}
              >
                <span
                  className={`badge badge-lg badge-rounded-circle me-2 ${
                    accordion === "immobilie" ? "bg-primary" : "bg-light"
                  }`}
                >
                  <span>2</span>
                </span>
                Daten zur Immobilie
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <div className="row">
                  <div className="col-sm-8">
                    {mzCookies.thirdParty && mapsLoaded ? (
                      <PlacesAutocomplete
                        value={city}
                        onChange={address => setCity(address)}
                        // onBlur={setFieldTouched}
                        // onSelect={handleSelect}
                        searchOptions={{
                          componentRestrictions: {
                            country: ["de", "ch", "at"],
                          },
                          // types: ["address"],
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="form-group form-floating mb-0">
                            <input
                              type="text"
                              name="city"
                              className={`form-control form-control-flush`}
                              id="city"
                              placeholder="PLZ / Stadt"
                              required
                              {...getInputProps()}
                            />
                            <label htmlFor="city">PLZ / Stadt</label>
                            {loading || suggestions.length ? (
                              <div
                                className="dropdown-menu dropdown-menu-xs show"
                                style={{
                                  overflowX: "hidden",
                                  minWidth: "150px",
                                }}
                              >
                                {loading && <div>Laden...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item"
                                  return (
                                    <a
                                      {...getSuggestionItemProps(suggestion, {
                                        className: `${className} dropdown-item`,
                                      })}
                                      key={suggestion.index}
                                    >
                                      <span>{suggestion.description}</span>
                                    </a>
                                  )
                                })}
                                <img
                                  src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
                                  style={{
                                    position: "absolute",
                                    bottom: "3px",
                                    right: "3px",
                                    height: "14px",
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : (
                      <div className="form-group form-floating mb-0">
                        <input
                          type="text"
                          name="city"
                          className="form-control form-control-flush"
                          id="city"
                          value={city}
                          onChange={e => setCity(e.target.value)}
                          placeholder="PLZ / Stadt"
                          required
                        />
                        <label htmlFor="city">PLZ / Stadt</label>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group form-floating km-after mb-0">
                      <input
                        type="number"
                        name="radius"
                        className="form-control form-control-flush"
                        id="radius"
                        value={radius}
                        onChange={e => setRadius(e.target.value)}
                        placeholder="Umkreis"
                      />
                      <label htmlFor="radius">Umkreis</label>
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-around align-items-center">
                  <div className="col-12 col-sm-8">Fläche:</div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group form-floating square-meter-after mb-0">
                      <input
                        name="area"
                        id="area"
                        className={`form-control form-control-flush`}
                        type="number"
                        value={area}
                        onChange={e => setArea(e.target.value)}
                      />
                      {/*<label htmlFor="area">Fläche</label>*/}
                    </div>
                  </div>
                </div>

                <RangeSlider
                  min={10}
                  max={200}
                  step={10}
                  size="lg"
                  value={area}
                  onChange={e => setArea(Number(e.target.value))}
                  tooltipLabel={val => getLabel(val, 10, 200)}
                />

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group form-floating mb-0">
                      <input
                        type="number"
                        name="radius"
                        className="form-control form-control-flush"
                        id="radius"
                        value={rooms}
                        onChange={e => setRooms(e.target.value)}
                        placeholder="Min. Anzahl Zimmer"
                      />
                      <label htmlFor="radius">Min. Anzahl Zimmer</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group form-floating euro-after mb-0">
                      <input
                        type="number"
                        name="radius"
                        className="form-control form-control-flush"
                        id="radius"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        placeholder="Maximaler Preis"
                      />
                      <label htmlFor="radius">Maximaler Preis</label>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="kontakt">
              <AccordionHeaderCustomScroll
                anchor="kontakt"
                onClick={e => {
                  setAccordion("kontakt")
                }}
              >
                <span
                  className={`badge badge-lg badge-rounded-circle me-2 ${
                    accordion === "kontakt" ? "bg-primary" : "bg-light"
                  }`}
                >
                  <span>3</span>
                </span>
                Ihre Kontaktdaten
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <div className="form-group form-floating mb-0">
                      <input
                        type="text"
                        className="form-control form-control-flush"
                        id="fullName"
                        name="fullName"
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                        placeholder="Vor- & Nachname"
                        required={true}
                      />
                      <label htmlFor="fullName">Vor- & Nachname</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group form-floating">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-flush"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="E-Mail"
                        required={true}
                      />
                      <label htmlFor="email">E-Mail</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group form-floating mb-0">
                      <input
                        type="text"
                        name="phone"
                        className={`form-control form-control-flush`}
                        id="phone"
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        placeholder="Telefon"
                        required={true}
                      />
                      <label htmlFor="phone">Telefon</label>
                    </div>
                  </div>
                </div>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    id="zustimmungDatenschutzGenerator"
                    name="zustimmungDatenschutzGenerator"
                    // checked={cookies.required}
                    required
                  />
                  <Label check for="zustimmungDatenschutzGenerator">
                    <span
                      className="text-gray-600 d-inline-block"
                      style={{
                        fontSize: "12px",
                        minHeight: "76px",
                      }}
                    >
                      Wir verwenden Ihre Daten ausschließlich im Zusam­men­hang
                      mit dem hier beschriebenen Service. Sie können Ihr
                      Einverständnis jederzeit telefonisch oder per E-Mail
                      widerrufen. Es gelten unsere{" "}
                      <a
                        href="/de/datenschutz/"
                        target="_blank"
                        className="text-muted"
                      >
                        <u>Datenschutz­bestimmungen</u>
                      </a>
                      .
                    </span>
                  </Label>
                </FormGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <hr className="card-meta-divider my-4" />

          <div className="d-flex justify-content-end align-items-center">
            {/*<a*/}
            {/*  href="/de/immobilienbewertung"*/}
            {/*  target="_blank"*/}
            {/*  className="btn-link me-2"*/}
            {/*>*/}
            {/*  <span className="d-none d-sm-inline">Stattdessen </span>*/}
            {/*  Immobilie bewerten lassen*/}
            {/*</a>*/}

            <button
              className={`btn btn-sm ${
                accordion === "kontakt" ? "btn-success" : "btn-primary"
              }`}
              type="submit"
              onClick={e => {
                if (accordion !== "kontakt") {
                  e.preventDefault()
                  if (accordion === "art") {
                    setAccordion("immobilie")
                  } else if (accordion === "immobilie") {
                    setAccordion("kontakt")
                  }
                }
              }}
              style={{ minWidth: "42%", maxHeight: "70px" }}
            >
              {isLoading && (
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {accordion === "kontakt" ? "Senden" : "Weiter"}
            </button>
          </div>
        </>
      )}
    </form>
  )
}

export default SearchAgent
