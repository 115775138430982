import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Accordion from "react-bootstrap/Accordion"

// todo: scrollTo maybe mobile only?
const AccordionHeaderCustomScroll = props => (
  <Accordion.Header
    as={"h3"}
    {...props}
    onClick={e => {
      if (props.onClick) {
        props.onClick(e)
      }
      if (!props.anchor) {
        return
      }
      // timeout depending of accordion collapse height (more content, longer animation duration, longer timeout to wait! even longer on mobile)
      setTimeout(() => scrollTo(`#${props.anchor}`), 500)
    }}
  >
    <span id={props.anchor} style={{ marginTop: "-300px" }} />
    {props.children}
  </Accordion.Header>
)

export default AccordionHeaderCustomScroll
